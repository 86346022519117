/* eslint-disable no-underscore-dangle */
import { useEffect, useState, useCallback } from 'react';
import CarouselEmbla from '@/core/carousel-embla';
import { SpaceCardSkinHome as CardSkinHome } from '@/core/dynamic';
import EMglobal from '@/helpers/emitter';

function SpacesPartners({ spaces, partners = [] }) {
  const [currentSpaces, setCurrentSpaces] = useState(spaces || []);
  let newPartners = partners;
  if (!newPartners) newPartners = [];

  const setSpacesFromOutside = useCallback((newSpaces) => {
    setCurrentSpaces(newSpaces);
  }, [currentSpaces, spaces]);

  useEffect(() => {
    EMglobal.on('spacesbycategory', setSpacesFromOutside);
    return () => {
      EMglobal.off('spacesbycategory', setSpacesFromOutside);
    };
  }, [currentSpaces]);

  useEffect(() => {
    setCurrentSpaces(spaces);
  }, [spaces]);
  // console.log('currentSpaces', currentSpaces);
  return (
    <section className="relative mt-5 lg:mt-0 lg:-top-[350px]">
      <div className="h-[400px] lg:h-[450px]">
        <CarouselEmbla enableKeyboardNavigation slidesToScroll={1} fromHome loop>
          {(currentSpaces || []).map((s) => (
            <div className="mx-2 overflow-hidden rounded-lg embla-slide-home" key={`cmphomezonespace${s.__id}`}>
              <CardSkinHome
                item={s}
                options={{
                  imageProps: {
                    space: true,
                  },
                }}
              />
            </div>
          ))}
        </CarouselEmbla>
      </div>
    </section>
  );
}

export default SpacesPartners;
